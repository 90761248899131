var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register"},[_c('Loader',{attrs:{"loading":_vm.showLoader}}),_c('div',{staticClass:"container-scroller"},[_c('div',{staticClass:"container-fluid page-body-wrapper full-page-wrapper"},[_c('div',{staticClass:"content-wrapper d-flex align-items-center auth"},[_c('div',{staticClass:"row w-100 flex-grow"},[_c('div',{staticClass:"col-xl-4 col-lg-6 mx-auto"},[_c('div',{staticClass:"auth-form-light text-left p-4"},[_vm._m(0),_c('h4',[_vm._v("New here?")]),_c('h6',{staticClass:"font-weight-light pb-3"},[_vm._v(" Signing up is easy. It only takes a few steps ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.signUp.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"first_name"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.first_name),expression:"user.first_name"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.first_name.$error,
                    },attrs:{"type":"text","id":"first_name","name":"first_name"},domProps:{"value":(_vm.user.first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "first_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.first_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" First Name is required ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"last_name"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.last_name),expression:"user.last_name"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.last_name.$error,
                    },attrs:{"type":"text","id":"last_name","name":"last_name"},domProps:{"value":(_vm.user.last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "last_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.last_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Last Name is required ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"lastName"}},[_vm._v("User Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.username),expression:"user.username"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.username.$error,
                    },attrs:{"type":"text","id":"username","name":"username"},domProps:{"value":(_vm.user.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "username", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" User Name is required ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                    },attrs:{"type":"email","id":"email","name":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Email is invalid")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                    },attrs:{"type":"password","id":"password","name":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password.required)?_c('span',[_vm._v("Password is required")]):_vm._e(),(!_vm.$v.user.password.minLength)?_c('span',[_vm._v("Password must be at least 9 characters")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password_confirmation"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password_confirmation),expression:"user.password_confirmation"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.user.password_confirmation.$error,
                    },attrs:{"type":"password","id":"password_confirmation","name":"password_confirmation"},domProps:{"value":(_vm.user.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password_confirmation", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.password_confirmation.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password_confirmation.required)?_c('span',[_vm._v("Confirm Password is required")]):(
                        !_vm.$v.user.password_confirmation.sameAsPassword
                      )?_c('span',[_vm._v("Passwords must match")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"},[_vm._v(" Register ")]),_c('div',{staticClass:"text-center mt-4 font-weight-light"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"text-primary",attrs:{"to":"/auth-pages/login"}},[_vm._v("Login")])],1)])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo-new.png")}})])
}]

export { render, staticRenderFns }